import React, { FC } from 'react';
import styled from 'styled-components';

import analytics from '../helpers/analytics';

import { SIGN_IN_LINK, INDEX_LINK, POSTS_LINK } from '../constants/links';

import illume from '../icons/illume.svg';

const TRACK_LOGIN = 'login - marketing';

const Container = styled.header`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.lightPeach2};

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 27px 43px;
  }
`;

const Logo = styled.img`
  height: 14px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.orange};
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
`;

const Section = styled.div`
  display: flex;
`;

const BlogLink = styled.a`
  color: ${(props) => props.theme.colors.orange};
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
`;

const Header: FC = () => {
  const handleClickLogin = () => {
    analytics.track(TRACK_LOGIN);
  };

  return (
    <Container>
      <Section>
        <Link href={INDEX_LINK}>
          <Logo src={illume} alt='illume' />
        </Link>
      </Section>
      <Section>
        <BlogLink href={POSTS_LINK}>blog</BlogLink>
        <Link href={SIGN_IN_LINK} onClick={handleClickLogin}>
          Login / Sign up
        </Link>
      </Section>
    </Container>
  );
};

export default Header;
