import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { theme } from '../styles/theme';

import { handleScroll } from '../helpers/scrollTop';
import {
  GIFTING_FAQS,
  MERCHANT_QUESTIONS_POLICIES_FAQS,
  PRODUCT_FAQS,
  SPLIT_ANY_GIFT_FAQS,
} from '../data/illume-faqs';
import { FaqElement } from '../sections/pricing/components/FaqElement';

const Layout = styled.section`
  display: flex;
  padding: 80px 10% 200px;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 150px 50px 250px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
`;

const PageTitle = styled.h2`
  font-weight: 900;
  font-size: 36px;
  line-height: 1.45;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 54px;
  }
`;

const Heading = styled.h2`
  margin-top: 52px;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
  }
`;

const HeadingList = styled.h4`
  margin-top: 42px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-size: 18px;
  line-height: 1.25;
  font-weight: 300;

  span {
    display: block;
  }

  ol {
    margin-left: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 24px;
    text-align: justify;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.orange};
`;

const LinkedTitle = styled.a`
  display: block;
  text-decoration: underline;
`;

const FaqContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
    justify-content: center;
    display: flex;
  }
`;

const SummaryTab = styled.p`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.lightPeach};
  border-radius: 10px;
  margin: 10px;
  &:hover {
    background-color: #e5a619;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 20px;
  }
`;

const Note = styled.p`
  margin-top: 32px;
  font-size: 18px;
  line-height: 1.25;
  font-weight: 300;
  font-style: italic;
`;

const Support: FC = () => {
  useEffect(() => {
    handleScroll();
  }, []);

  function redirectToElementWithHeight(id: string) {
    // Redirect to the element with the given ID
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='illume FAQs' />
      <Header />
      <Layout>
        <Container>
          <PageTitle>illume FAQs</PageTitle>
          <Paragraph>
            Hi! Welcome to illume’s FAQ page. Feel free to find FAQs below or reach out with
            questions at: <Link href='mailto: email@tryillume.com'>email@tryillume.com</Link>.
          </Paragraph>
          <SummaryContainer>
            <Tabs>
              <span>General</span>
              <SummaryTab onClick={() => redirectToElementWithHeight('product-faq')}>
                &#10067; Product FAQs
              </SummaryTab>
              <SummaryTab onClick={() => redirectToElementWithHeight('gifting-faq')}>
                &#127873; Gifting FAQs
              </SummaryTab>
            </Tabs>
            <Tabs>
              <span>Specific FAQs & Updates</span>
              <SummaryTab onClick={() => redirectToElementWithHeight('split-faq')}>
                &#129309; &quot;Split any Gift&quot; FAQs
              </SummaryTab>
              <SummaryTab onClick={() => redirectToElementWithHeight('merchant-faq')}>
                &#127980; Merchant Questions & Policies
              </SummaryTab>
            </Tabs>
          </SummaryContainer>
          <Note>
            Note: we’re based in the U.S. so if you reach out at email@tryillume.com, yes, an actual
            human will respond and help out.
          </Note>
          <Heading id='general'>General</Heading>
          <HeadingList id='product-faq'>&#10067; Product FAQs</HeadingList>
          <FaqContainer>
            {PRODUCT_FAQS.map((faq, index) => {
              return <FaqElement key={index} faq={faq} />;
            })}
          </FaqContainer>
          <HeadingList id='gifting-faq'>&#127873; Gifting FAQs</HeadingList>
          <FaqContainer>
            {GIFTING_FAQS.map((faq, index) => {
              return <FaqElement key={index} faq={faq} />;
            })}
          </FaqContainer>
          <Heading id='specifiq-faqs'>Specific FAQs & Updates</Heading>
          <HeadingList id='split-faq'>&#129309; &quot;Split any Gift&quot; FAQs</HeadingList>
          <FaqContainer>
            {SPLIT_ANY_GIFT_FAQS.map((faq, index) => {
              return <FaqElement key={index} faq={faq} />;
            })}
          </FaqContainer>
          <HeadingList id='merchant-faq'>&#127980; Merchant Questions & Policies</HeadingList>
          <FaqContainer>
            {MERCHANT_QUESTIONS_POLICIES_FAQS.map((faq, index) => {
              return <FaqElement key={index} faq={faq} />;
            })}
          </FaqContainer>
        </Container>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default Support;
