type PropObj = Record<string, unknown>;

// not sure about this one?  maybe use 'marketing'?
// see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
const CATEGORY = undefined;

const track = (event: string, properties?: PropObj): void => {
  if (!(window && window.analytics && window.analytics.track)) return;
  window.analytics.track(event, properties);
};

const page = (name?: string, properties?: PropObj): void => {
  if (!(window && window.analytics && window.analytics.page)) return;
  window.analytics.page(CATEGORY, name, properties);
};

export default { track, page };
