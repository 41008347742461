const LOCAL_STORAGE_KEY = 'historyLength';

export const handleScroll = () => {
  const previousHistoryLength = Number(window.localStorage.getItem(LOCAL_STORAGE_KEY) || -1);
  const currentHistoryLength = window.history.length;

  if (currentHistoryLength > previousHistoryLength) {
    window.scrollTo(0, 0);
  }

  window.localStorage.setItem(LOCAL_STORAGE_KEY, currentHistoryLength.toString());
};
