import React, { FC } from 'react';
import styled from 'styled-components';

import facebook from '../icons/facebook.svg';
import twitter from '../icons/twitter.svg';
import instagram from '../icons/instagram-black.svg';

import {
  INSTAGRAM_LINK,
  CONTACT_US_LINK,
  TWITTER_LINK,
  FACEBOOK_LINK,
  PRIVACY_LINK,
  TERMS_LINK,
} from '../constants/links';

const Container = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 27px 10%;
  display: flex;
  font-size: 14px;
  line-height: 1.57;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    align-items: flex-start;
    padding: 36px 64px;
  }
`;

const Gap = styled.div`
  display: none;
  order: 4;

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: block;
    flex-grow: 1;
    order: 2;
  }
`;

const Copyright = styled.h3`
  color: ${(props) => props.theme.colors.blackTextLight};
  order: 3;
  text-align: center;
  margin-top: 16px;
  max-width: 250px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    text-align: left;
    margin-top: 0;
    max-width: none;
  }
`;

const LinksContainer = styled.div`
  color: ${(props) => props.theme.colors.blackText};
  order: 2;
  margin-top: 16px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 3;
    margin-top: 0;
  }
`;

const TextLink = styled.a`
  :not(:first-of-type):before {
    content: '|';
    margin: 0 5px;
  }
`;

const IconsContainer = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 4;
  }
`;

const IconLink = styled.a`
  padding: 0 8px;
  line-height: 1;
`;

const Footer: FC = () => (
  <Container>
    <Copyright>Copyright 2022. All rights reserved. dedicated to those who shine.</Copyright>
    <Gap />
    <LinksContainer>
      <TextLink href={CONTACT_US_LINK} target='_blank'>
        contact us
      </TextLink>
      <TextLink href={PRIVACY_LINK}>privacy</TextLink>
      <TextLink href={TERMS_LINK}>terms</TextLink>
    </LinksContainer>
    <IconsContainer>
      <IconLink href={INSTAGRAM_LINK} target='_blank' rel='noopener'>
        <img src={instagram} alt='instagram' />
      </IconLink>
      <IconLink href={FACEBOOK_LINK} target='_blank' rel='noopener'>
        <img src={facebook} alt='facebook' />
      </IconLink>
      <IconLink href={TWITTER_LINK} target='_blank' rel='noopener'>
        <img src={twitter} alt='twitter' />
      </IconLink>
    </IconsContainer>
  </Container>
);

export default Footer;
