import * as React from 'react';
import dompurify from 'isomorphic-dompurify';
import styled, { css } from 'styled-components';
import { theme } from '../../../styles/theme';

import ChevronIcon from './ChevronIcon';
import { HeightBasedCollapsible } from './HeightBasedCollapsible';

const colors = {
  primary: theme.colors.orange,
  contrastText: theme.colors.lightPeach2,
  gray100: theme.colors.gray100,
  backgroundGray: theme.colors.lightPeach,
};

const Container = styled.div<{ open: boolean }>`
  color: ${({ open }) => (open ? colors.contrastText : colors.gray100)};
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  padding: 16px 20px;
  background-color: ${({ open }) => (open ? colors.primary : colors.backgroundGray)};
`;

const Title = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  display: flex;
  column-gap: 16px;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${({ open }) => (open ? '12px' : 0)};
`;

const Text = styled.span`
  font-weight: 420;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0em;
`;

const TextAnswer = styled.span`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0em;

  & > a {
    text-decoration: underline;
  }
`;

const ChevronIconContainer = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Faq {
  question: string;
  answer: string;
}

export const FaqElement = ({ faq }: { faq: Faq }) => {
  // collapse state
  const [open, setOpen] = React.useState(false);

  const createMarkup = () => {
    return { __html: dompurify.sanitize(faq.answer) };
  };

  return (
    <Container open={open}>
      <Title
        open={open}
        onClick={(e) => {
          e.preventDefault();
          return setOpen(!open);
        }}
      >
        <ChevronIconContainer>
          <ChevronIcon
            direction={open ? 'down' : 'right'}
            color={open ? colors.contrastText : colors.gray100}
            opacity='1'
          />
        </ChevronIconContainer>
        <Text>{faq.question}</Text>
      </Title>
      <HeightBasedCollapsible isOpen={open}>
        <TextAnswer dangerouslySetInnerHTML={createMarkup()} />
      </HeightBasedCollapsible>
    </Container>
  );
};
