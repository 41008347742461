import {
  CONTACT_US_LINK,
  MERCHANT_INTEREST_LINK,
  PRICING_B2C_LINK,
  PRIVACY_LINK,
  SPLIT_ANY_GIFT_LINK,
  STRIPE_LINK,
  TERMS_LINK,
} from '../constants/links';

export const PRODUCT_FAQS = [
  {
    question: 'Is there a limit to the number of contributors per card?',
    answer: `There's no limit to the number of contributors on a card. Go crazy and invite as many individuals as you'd like!`,
  },
  {
    question: 'Is illume free to use?',
    answer: `We want to democratize the sharing of positive sentiments. Thus, each account holder is able to send 2 free cards and access illume's celebration platform. If you'd like to send more cards and gifts, please see our pricing page <a href="${PRICING_B2C_LINK}" target='_blank'>here</a>`,
  },
  {
    question: 'Does illume sell my data to anybody?',
    answer: `Yuck, absolutely not.`,
  },
  {
    question: 'Can I resend a card?',
    answer: `You are able to resend the card, if the recipient has not yet read the card. All you have to do is access the card in the "sent" section of the "my cards" page. When you select "view details" of the particular card, you should see a field to input the email or SMS of the recipient to resend the card.`,
  },
  {
    question: 'Can the recipient always access the card they receive?',
    answer: `Yep, the recipient will always have access to the card that they receive.`,
  },
  {
    question: 'Do contributors need to create an account to add a note?',
    answer: `No, contributors do not need to create an account on illume to add to a card.`,
  },
  {
    question: 'Do recipients need to create an account to view the card?',
    answer: `No, recipients do not need to create an account on illume to view the card.`,
  },
  {
    question:
      'Can I view the list of contributors to my card before sending it, to make sure everyone added notes?',
    answer: `Yes! In the “card details” page, navigate to the “in the works” section and then click on the “show list” option underneath the deadline to view everyone who has contributed to your card.`,
  },
  {
    question: `What happens to funds added to a gift if we don't raise enough money for the gift?`,
    answer: `The card—specifically, the notes added by contributors—would be sent and the gift wouldn't be included in that card. Those who contributed funds should not be charged. The charge for contributors to a gift should only happen when the recipient puts in their address to receive a gift.`,
  },
  {
    question:
      'Can a recipient download the card they receive in order to keep the notes on it, or do they lose access to the card after a period of time?',
    answer: `Yes! Recipient can download the card as a PDF. Recipients will always have access to the card on illume.`,
  },
  {
    question: 'Can I send a card to more than one recipient?',
    answer: `Absolutely! When filling out the "Basic Info" page of creating a card, under "Recipient Name" you'll see a "+" button that allows you to add as many additional recipients as you want for one group card.`,
  },
  {
    question: `As the card creator, can I change the order in which the recipient sees the notes on the card?`,
    answer: `Not at this time. We normally order the notes according to how early a user contributes to the card. If you are the first to contribute, your note is shown at the beginning of the card for the recipient. If you are the last to contribute, your note also shows up last for the recipient.`,
  },
  {
    question:
      'Can I send a card to a recipient without inviting any other contributors besides myself?',
    answer: `While illume is designed to be a group card tool, you can technically still send one-to-one cards without inviting anyone else to add notes. When you’re creating your card and reach the page that prompts you to invite others, clicking on the button that copies the invite link to your clipboard will allow you to progress to the next page without actually having to input anybody’s email address or phone number to invite them to your card.`,
  },
  {
    question:
      'When the recipient sends a reply, can everyone who contributed to the card see it, or just the card creator?',
    answer: `The recipient’s reply goes to everyone. It's essentially a reply-all.`,
  },
  {
    question:
      'If I signed in as a guest, how can I make sure I can access my card tomorrow after exiting illume’s webpage?',
    answer: `Not to worry, you can access your card again simply by logging in via the email you created the card with as a guest.`,
  },
  {
    question: `Can a contributor add more than one note to a card?`,
    answer: `If you want, sure! After you’ve added your first note, simply go back to the same contributor link the card creator shared with you and write another note.`,
  },
  {
    question: `Can contributors still add notes to my card if it’s in the “ready to send” state?`,
    answer: `The short answer is yes! As per usual, they just have to click on the contributor link you send them and add their note. If you want to change the "ready to send" state, all you have to do is change the card deadline (i.e. the date by which illume reminds you to send your card).`,
  },
  {
    question: `Is my card automatically delivered to the recipient on the reminder date that I set?`,
    answer: `On the card details page for your card, you can specify whether you'd like the card to be automatically sent at a later date. Note: the card can only be scheduled sent 24 hours or later than your intended delivery date. If you don't want to automatically send your card, that's fine. You can also manually send your card to your recipient via SMS or email.`,
  },
  {
    question: `Will the card be delivered when I send it, or can I schedule delivery?`,
    answer: `You can schedule delivery of the card, if you'd like! See the above question for more details.`,
  },
  {
    question: `Is there a way for card creators to preview and approve contributors’ notes before they are added to a card?`,
    answer: `There’s no high-level way to approve notes before they’re added to your card. As a precaution, we do use sentiment analysis to screen each note to reduce the likelihood that a hateful note is submitted.`,
  },
  {
    question: `As a card creator, is there a way to disable the anonymous note feature for every contributor to my card?`,
    answer: `The option to be anonymous is up to each note contributor. There’s no high-level way to disable it for everybody.`,
  },
  {
    question: `Is it possible to edit or delete a note on a card once it's been added to the card, but not yet sent to the recipient?`,
    answer: `If you are a contributor of a card and create an account after contributing to that card, you are able to edit or delete your note in the "card details" section of the "my cards" page.

    Initiators—given that they need an account to create the card—are able to edit and delete their own note also in the "card details" section of card in the "my cards" page.`,
  },
  {
    question: `Why can't I see the reply to my card?`,
    answer: `The reply to a card is solely based on whether a recipient wants to reply to a card. If the recipient replies to the card, then the reply will show up in the "card details" of the card, which should be located in the "sent" section in the "my cards" page.

    You should also get an email that you received a reply from the recipient.`,
  },
  {
    question: `Can individuals add to the card after it is sent?`,
    answer: `Yes, contributors to a card can add notes to the card after it is sent to the recipient.`,
  },
  {
    question: `How can I print a card I received?`,
    answer: `Right now, we don't offer card printing services. We're excited to potentially offer it in the near future.`,
  },
  {
    question: `How do I delete a card?`,
    answer: `For a card in the "pending" section of your "my cards" page, scroll to the bottom of the card details page of the card and you should see a button that says, "delete card." You cannot delete cards that are already sent or received by a recipient.`,
  },
  {
    question: `Can I change the card theme/background colors for a card?`,
    answer: `After you create a card, the theme you select is set and unchangeable. So if you want to change the background theme/colors, you'd have to create a new card. The reason: each theme has backgrounds that are designed to harmoniously go together when a recipient views a card. We wanna make sure the card looks really good!`,
  },
  {
    question: `How can I delete my account?`,
    answer: `Reach out to <a href="${CONTACT_US_LINK}" target="_blank" >email@tryillume.com</a> and we'll be more than happy to take care of it!`,
  },
  {
    question: `How can I request a refund for a purchase?`,
    answer: `Please reach out to <a href="${CONTACT_US_LINK}" target="_blank" >email@tryillume.com</a> and we’ll help out. Sales are final for gift and gift card purchases - recipients can exchange funds for other gifts or gift cards, if they are not satisfied.`,
  },
];

export const GIFTING_FAQS = [
  {
    question: `What happens to funds added to a gift if we don't raise enough money for the gift?`,
    answer: `The card—specifically, the notes added by contributors—would be sent and the gift wouldn't be included in that card. Those who contributed funds should not be charged. The charge for contributors to a gift should only happen when the recipient puts in their address to receive a gift.`,
  },
  {
    question: `When are contributors charged for the gift?`,
    answer: `The moment that a recipient receives that card and puts in their shipping address for the gift. Thus, if the recipient reads the card and doesn't put in their address to receive the gift, contributors will not be charged for the gift.`,
  },
  {
    question: `How do I send the gift?`,
    answer: `All you need to send the gift is the recipient email or mobile phone number. The recipient will receive a link where they can read their card and input their shipping address to receive their gift. Thus, you don't need the recipient's shipping address to send them a gift.`,
  },
  {
    question: `What if I want to change the gift associated with a card?`,
    answer: `If you want to change the gift associated with a card, you'll have to create a new card. Yes, that means contributors would have to add notes again to the new card. That said, we expect to allow more flexibility in the future.`,
  },
  {
    question: `I don't see a gift that I want, what can you do?`,
    answer: `You can split the cost of any gift online on illume <a href="${SPLIT_ANY_GIFT_LINK}" target='_blank'>here</a>. Simply enter the URL of the gift and split the cost of the gift with your group. If you'd like an incredible merchant to be featured on the illume marketplace, tell us at <a href="${CONTACT_US_LINK}" target="_blank" >email@tryillume.com</a>. Our team is constantly sourcing and partnering with merchants across the U.S. There's a good chance we can help.`,
  },
];

export const SPLIT_ANY_GIFT_FAQS = [
  {
    question: `The URL I uploaded isn't working, what do I do to fix it?`,
    answer: `After submitting the URL, you'll get to a page called, "Confirm gift details." On that page, you can input the total price of the good, the variation, and even edit the product description. That way, if uploading the URL doesn't work, you're able to quickly input it yourself.`,
  },
  {
    question: `What if there's a specific variant of the product I want to split?`,
    answer: `We got you. After uploading the product, you'll see an optional text box that allows you to specify what variation—whether that's color, size, etc.—that you'd like.`,
  },
  {
    question: `Can I split the gift for something outside the U.S.?`,
    answer: `We don't handle shipments outside the U.S. Thus, be sure the vendor ships in the U.S. If you make a mistake, reach out to us at <a href="${CONTACT_US_LINK}" target="_blank" >email@tryillume.com</a>. Note that all sales are final and we can offer you a credit or exchange the purchase for a different gift.`,
  },
  {
    question: `What happens to funds added to a gift if we don't raise enough money for the gift?`,
    answer: `The card—specifically, the notes added by contributors—would be sent and the gift wouldn't be included in that card. Those who contributed funds should not be charged. The charge for contributors to a gift should only happen when the recipient puts in their address to receive a gift.`,
  },
  {
    question: `When are contributors charged for the gift?`,
    answer: `The moment that a recipient receives that card and puts in their shipping address for the gift. Thus, if the recipient reads the card and doesn't put in their address to receive the gift, contributors will not be charged for the gift.`,
  },
  {
    question: `How do I send the gift?`,
    answer: `All you need to send the gift is the recipient email or mobile phone number. The recipient will receive a link where they can read their card and input their shipping address to receive their gift. Thus, you don't need the recipient's shipping address to send them a gift.`,
  },
  {
    question: `What if I want to change the gift associated with a card?`,
    answer: `If you want to change the gift associated with a card, you'll have to create a new card. Yes, that means contributors would have to add notes again to the new card. That said, we expect to allow more flexibility in the future.`,
  },
];

export const MERCHANT_QUESTIONS_POLICIES_FAQS = [
  {
    question: `How can I get in touch with illume for support?`,
    answer: `If you are a merchant who is approved to have your product on illume and you need support, please reach out to <a href="${CONTACT_US_LINK}" target="_blank" >email@tryillume.com</a>.

        If you'd like to apply to have your product featured on illume, you can apply <a href="${MERCHANT_INTEREST_LINK}" target='_blank'>here</a>. We'll then get in touch if it is a fit.`,
  },
  {
    question: `How does commission work on illume?`,
    answer: `Currently, illume takes a 15% take rate from each order—excluding gift cards—that happens on illume. For example, let's say a group pools $125 for a gift + $20 for shipping. illume takes 15% from the $125 - 2.9% credit card processing fee + 30 cents Stripe fee. The rest including shipping is sent to the merchant.

        We use <a href="${STRIPE_LINK}" target='_blank'>Stripe</a> to process payments and send payments to merchants 7-14 business days. By uploading a product on illume, a merchant agrees to our <a href="${PRIVACY_LINK}" target='_blank'>privacy policy</a>, <a href="${TERMS_LINK}" target='_blank'>terms of service</a>, and pricing model as outlined in this article.`,
  },
  {
    question: `How can my product be featured on illume?`,
    answer: `Joining illume as a merchant is currently invite-only; that said, merchants can apply <a href="${MERCHANT_INTEREST_LINK}" target='_blank'>here</a> to be featured on illume. We will reach out if there's a fit. There are a number of factors we look into including, but not limited to, being able to handle demand, passion for customers, product type, and sustainability.

        Each merchant on illume is joining our vision to create a world where everyone is celebrated for who they are. We are humbled to partner with merchants who join us in making this vision a reality.`,
  },
];
